import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import moment from 'moment'

import Link from '../utils/link'

import Seo from '../components/seo'
import Search from '../assets/images/search-blue.svg'
import DownArrow from '../assets/images/down_arrow-white.svg'
import Pdf from '../assets/images/pdf.svg'

class ProductRecallsPage extends Component {

  render() {

    const recalls = this.props.data.allWpRecall.nodes

    return (
      <>
      	<Seo title='Product Recalls - Lenoxx' bodyClass='product-recalls' description='' />
        <section className='recalls-header'>
        	<div className='recalls-header__inner'>
            <div>
          		<h1>Product Recalls</h1>
              <p>This is a precaution to ensure the safety of the public<br />
              For more information, please contact Lenoxx or ACCC Info Centre: 1300 302 502<br />
              See www.recalls.gov.au for Australian Product Recall Information</p>
            </div>
            <Link className='btn btn--blue' to='/contact-us'>Contact Us</Link>
        	</div>
        </section>
        <div className='basic__inner'>
          <div className='basic__column'>
            <h4>Actions for Consumers</h4>
            <ul>
              <li>Immediately stop using the recalled item and unplug it from the socket outlet.</li>
              <li>Contact the place of purchase for a full refund.</li>
              <li>If you require further information regarding the recall please email service@lenoxx.com.au or call 03 9267 2100.</li>
            </ul>
          </div>
          <div className='basic__column'>
            <h4>Actions for Retailers</h4>
            <ul>
              <li>Please do a count of what stock you have left and inform us of the quantity to obtain a credit for the recalled stock.</li>
              <li>After receiving confirmation from our office please destroy it.</li>
              <li>We need to inform the customers who purchased them to return the stock to their place of purchase for a refund and in turn you destroy the stock and claim for a credit.</li>
            </ul>
          </div>
        </div>
        <section className='breadcrumbs'>
        	<div className='breadcrumbs__inner'>
        		<ul>
        			<li>Product Recalls</li>
        			<li>All</li>
        		</ul>
        	</div>
        </section>
        <section className='manuals manuals--recalls'>
        	<div className='manuals__inner'>
        		<div className='manuals__titles'>
              <h4>Date</h4>
        			<h4>Model No.</h4>
        			<h4>Product Name</h4>
        			<h4>Recall Information</h4>
        		</div>
            <div className='manuals__items'>
            { recalls?.length > 0 ? recalls.map((el, i) => (
              <div className='manuals__item' key={i}>
                <div className='manuals__date'>
                  <h4>Date</h4>
                  <span>{ moment(el.date).format('d/M/YY') }</span>
                </div>
                <div className='manuals__model'>
                  <h4>Model No.</h4>
                  <span>{ el.acf.modelNo }</span>
                </div>
                <div className='manuals__product'>
                  <h4>Product Name</h4>
                  <span>{ el.title }</span>
                </div>
                <div className='manuals__downloads'>
                  <h4>Available Downloads</h4>
                  <ul>
                    { el.acf.downloads?.map((item, j) => (
                      <li key={i}>
                        <a href={item.file?.localFile?.publicURL} download>
                          <img src={Pdf} alt={item.name} />
                          <span>{ item.name } — { el.title }</span>
                        </a>
                      </li>
                    )) }
                  </ul>
                </div>
              </div>
            )) : <div className='manuals__item'>No product recalls at this time.</div>}
            </div>
        	</div>
        </section>
      </>
    )
  }
}

export const query = graphql`
  query {
    allWpRecall {
      nodes {
        title
        id
        title
        slug
        date
        acf {
					downloads {
					  name
            file {
							localFile {
								publicURL
              }
            }
          }
          modelNo
        }
      }
    }
  }
`

export default ProductRecallsPage
